import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/css/tailwind.css";



import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { initializeApp } from "firebase/app";
const firebaseConfig = {
  databaseURL:
    "https://footballstadium-1cbab-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

initializeApp(firebaseConfig);

createApp(App).use(router).use(VueSweetalert2).mount("#app");
