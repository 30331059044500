<template>

  <div
    class="min-h-screen bg-zinc-950 bg-fixed hover:bg-fixed"
    style="background-image: url('https://img.freepik.com/free-photo/green-fake-grass-background_53876-16313.jpg?t=st=1704364692~exp=1704365292~hmac=1dca88b81e9b0d01053776a008cd2e300f58b590f899b3599ed7782162690744');"
  >
    <!-- ส่วนของ header -->
    <Header />

    <div class="pt-20 min-h-screen transition-position">
      <div class="p-5 xl:max-w-7xl xl:mx-auto pb-20">
        
        <slot />
      </div>
    </div>

    <Footer />

  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import axios from "axios";

export default {
  name: "HuayPage",

  components: {
     Header,
     Footer,
  },

  data() {
    return {
      background:
        "https://img.freepik.com/free-photo/green-fake-grass-background_53876-16313.jpg?t=st=1704364692~exp=1704365292~hmac=1dca88b81e9b0d01053776a008cd2e300f58b590f899b3599ed7782162690744",

      bg1: require("@/assets/images/arena1.png"),
      bg2: "https://i.pinimg.com/564x/76/97/c4/7697c433f89db340dfda34e56bdb3279.jpg",
    };
  },
};
</script>

<style scoped></style>
