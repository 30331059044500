<template lang="">
  <LayoutBBgun>
    <div class="text-center">
      <img
        class="h-24 w-24 md:h-40 md:w-40 object-contain mx-auto"
        :src="require('@/assets/images/logoMwhite.png')"
        alt="arena1"
      />

      <div
        style="
          background-color: black;
          border-radius: 0.25em;
          display: inline-block;
          padding: 0.5em;
        "
      >
        <div class="text-zinc-100 text-lg md:text-2xl">สนามบีบีกัน</div>
      </div>

      <div class="p-1 rounded-lg bg-zinc-950 mt-5">
        <div class="text-center text-zinc-50">
          <!-- รูปขนาดใหญ่ของสนาม -->
          <div class="bg-cover bg-center h-full relative">
            <img
              class="h-full w-full object-cover"
              :src="selectImage"
              alt="ImageStadium"
            />
          </div>
        </div>
      </div>

      <!-- รูปขนาดเล็กของสนาม -->
      <div class="text-center grid grid-cols-12 gap-2 mt-1">
        <div
          @click="selectImage = require('@/assets/images/gun1.png')"
          class="cursor-pointer col-start-2 col-span-2 text-zinc-50 border-2 border-zinc-950"
        >
          <img :src="require('@/assets/images/gun1.png')" alt="stadium1" />
        </div>
        <div
          @click="selectImage = require('@/assets/images/gun2.png')"
          class="cursor-pointer col-span-2 text-zinc-50 border-2 border-zinc-950"
        >
          <img :src="require('@/assets/images/gun2.png')" alt="stadium2" />
        </div>
        <div
          @click="selectImage = require('@/assets/images/gun3.png')"
          class="cursor-pointer col-span-2 text-zinc-50 border-2 border-zinc-950"
        >
          <img :src="require('@/assets/images/gun3.png')" alt="stadium3" />
        </div>
        <div
          @click="selectImage = require('@/assets/images/gun4.png')"
          class="cursor-pointer col-span-2 text-zinc-50 border-2 border-zinc-950"
        >
          <img :src="require('@/assets/images/gun4.png')" alt="stadium4" />
        </div>

        <div
          @click="selectImage = require('@/assets/images/gun5.png')"
          class="cursor-pointer col-span-2 text-zinc-50 border-2 border-zinc-950"
        >
          <img :src="require('@/assets/images/gun5.png')" alt="stadium5" />
        </div>
      </div>

      <!-- เวลา -->
      <div class="bg-zinc-950 mt-2 md:mt-5 rounded-md">
        <div class="text-center text-gray-300 p-3 md:pt-7">
          <div class="text-md md:text-3xl">{{ currentDate }}</div>
          <div class="text-gray-300 text-2xl md:text-5xl md:mt-3 md:pb-5">
            {{ currentHour }}:{{ currentMinute }}:{{ currentSecond }}
          </div>
        </div>
      </div>

      <!-- ส่วนของข้อมูลสนามที่จองแล้ว -->
      <div
        v-if="isShow1 == true && editedItem.value.status == 2"
        class="text-zinc-200"
      >
        <div class="md:grid md:grid-cols-3 mt-5 md:mt-10">
          <div
            class="col-start-2 bg-cover bg-center relative border-4 border-zinc-950 overflow-hidden rounded-md"
          >
            <img class="object-cover" :src="grassImage" alt="ImageStadium" />

            <div class="absolute inset-0 flex items-center justify-around">
              <div>
                <div class="bg-black rounded-lg px-4">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    ชื่อผู้จอง
                  </div>
                </div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    เบอร์โทร
                  </div>
                </div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    เวลา
                  </div>
                </div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    สถานะ
                  </div>
                </div>
              </div>
              <div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    {{ editedItem.value.name }}
                  </div>
                </div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    {{ editedItem.value.tel }}
                  </div>
                </div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    {{ editedItem.value.time }}
                  </div>
                </div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    จองสำเร็จ
                    <!-- {{  editedItem.value.status }} -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ส่วนของข้อมูลสนามที่ยังไม่จอง -->
      <div v-if="isShow1 == true" class="text-zinc-200">
        <div
          v-if="editedItem.value.status == 1 || editedItem.value.status == 3"
        >
          <div class="md:grid md:grid-cols-3 mt-5 md:mt-10">
            <div
              class="col-start-2 bg-cover bg-center relative border-4 border-zinc-950 overflow-hidden rounded-md"
            >
              <img class="object-cover" :src="grassImage" alt="ImageStadium" />

              <div class="absolute inset-0 flex items-center justify-around">
                <div>
                  <div class="bg-black rounded-lg px-4">
                    <div class="text-zinc-200 text-xl md:text-xl text-center">
                      ชื่อผู้จอง
                    </div>
                  </div>

                  <div class="bg-black rounded-lg px-4 mt-1">
                    <div class="text-zinc-200 text-xl md:text-10xl text-center">
                      เบอร์โทร
                    </div>
                  </div>
                  <div class="bg-black rounded-lg px-4 mt-1">
                    <div class="text-zinc-200 text-xl md:text-10xl text-center">
                      เวลา
                    </div>
                  </div>
                  <div class="bg-black rounded-lg px-4 mt-1">
                    <div class="text-zinc-200 text-xl md:text-10xl text-center">
                      สถานะ
                    </div>
                  </div>
                </div>
                <div>
                  <div class="text-zinc-200 text-sm md:text-sm text-center">
                    <input
                      v-model="editedItem.value.name"
                      class="h-8 p-2 md:p-2 border rounded-lg text-zinc-950 text-center"
                      type="text"
                      placeholder="ชื่อผู้จอง"
                    />
                  </div>
                  <div
                    class="text-zinc-200 text-sm md:text-sm text-center mt-1"
                  >
                    <input
                      v-model="editedItem.value.tel"
                      class="h-8 p-2 md:p-2 border rounded-lg text-zinc-950 text-center"
                      type="tel"
                      placeholder="เบอร์โทร"
                    />
                  </div>
                  <div class="bg-black rounded-lg px-4 mt-1">
                    <div class="text-zinc-200 text-xl md:text-10xl text-center">
                      {{ editedItem.value.time }}
                    </div>
                  </div>
                  <div class="bg-black rounded-lg px-4 mt-1">
                    <div class="text-zinc-200 text-xl md:text-10xl text-center">
                      ว่าง
                      <!-- {{  editedItem.value.status }} -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="absolute bottom-0 text-center"></div>
            </div>
          </div>
        </div>

        <div
          v-if="editedItem.value.status == 3"
          class="md:grid md:grid-cols-3 mt-4 pb-2"
        >
          <div class="col-start-2 text-center mt-2">
            <div class="text-zinc-100">QRcode ชำระเงิน</div>
            <img
              class="h-24 w-24 md:h-40 md:w-40 object-contain mx-auto mt-3"
              :src="require('@/assets/images/QRcode.png')"
              alt="arena1"
            />
          </div>
        </div>

        <div class="md:grid md:grid-cols-3 mt-4 pb-2">
          <div class="col-start-2">
            <div class="flex justify-between gap-2">
              <button
                v-if="editedItem.value.status == 1"
                @click="saveChanges"
                class="w-full cursor-pointer bg-indigo-500 hover:bg-indigo-700 text-white px-4 py-1 rounded transition duration-300"
              >
                ชำระเงิน
              </button>
              <button
                v-if="editedItem.value.status == 3"
                @click="saveChanges2"
                class="w-full cursor-pointer bg-indigo-500 hover:bg-indigo-700 text-white px-4 py-1 rounded transition duration-300"
              >
                ยืนยันการจอง
              </button>

              <button
                class="w-full cursor-pointer bg-amber-500 hover:bg-amber-700 text-white px-4 py-1 rounded transition duration-300"
                @click="isShow1 = false"
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- สถานะสนาม -->
      <div class="grid grid-cols-2 gap-2 p-1 md:mt-1">
        <div class="bg-zinc-950 rounded-lg p-2">
          <h1 class="text-center text-zinc-100 text-lg md:text-xl">
            สถานะสนาม
          </h1>

          <div class="grid grid-cols-2 gap-1">
            <div class="text-center text-zinc-100">
              <div class="text-center text-zinc-100">เวลา</div>
              <!-- ส่วน loop ของเวลา -->
              <div
                v-for="(item, index) in items"
                :key="index"
                class="text-zinc-100 text-sm md:text-lg"
              >
                <div class="bg-zinc-500 border-zinc-900 md:border-2">
                  {{ item.value.time }}
                </div>
              </div>
            </div>

            <div class="text-center text-zinc-100">
              <div class="text-center text-zinc-100">สนามบอล</div>
              <!-- ส่วน สถานะของสถานะ -->
              <div
                v-for="(item, index) in items"
                :key="index"
                class="text-zinc-100 text-sm md:text-lg"
              >
                <div v-if="item.value.status == 1">
                  <!-- @click="payTab(item.key)" -->
                  <!-- @click="toggleShow1(item.value, item.key)" -->
                  <div
                    @click="toggleShow1(item.value, item.key)"
                    class="cursor-pointer bg-lime-500 border-zinc-900 md:border-2"
                  >
                    ว่าง
                  </div>
                </div>
                <div v-if="item.value.status == 2">
                  <div
                    @click="toggleShow1(item.value, item.key)"
                    class="cursor-pointer bg-red-600 border-zinc-900 md:border-2"
                  >
                    จองแล้ว
                  </div>
                </div>
                <div v-if="item.value.status == 3">
                  <div
                    @click="toggleShow1(item.value, item.key)"
                    class="cursor-pointer bg-amber-500 border-zinc-900 md:border-2"
                  >
                    รอชำระเงิน
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ข้อมูลสนาม -->
        <div class="bg-zinc-950 rounded-lg p-2">
          <h1 class="text-center text-zinc-100 text-lg md:text-xl">
            ข้อมูลสนาม
          </h1>

          <div class="text-start text-zinc-100 text-sm md:text-lg px-2 mt-2">
            - สนามบีบีกัน 7 คน
          </div>
          <div class="text-start text-zinc-100 text-sm md:text-lg px-2 mt-2">
            - ร้านขายอุปกรณ์กีฬา เสื้อ กางเกง ถุงเท้า
          </div>
          <div class="text-start text-zinc-100 text-sm md:text-lg px-2 mt-2">
            - เสื้อ กางเกง ถุงเท้า ถุงมือ ให้เช่า
          </div>
          <div class="text-start text-zinc-100 text-sm md:text-lg px-2 mt-2">
            - อาหาร และเครื่องดื่ม
          </div>
          <div class="text-start text-zinc-100 text-sm md:text-lg px-2 mt-2">
            - มีลานจอดรถ
          </div>
        </div>
      </div>
    </div>
  </LayoutBBgun>
</template>

<script>
import LayoutBBgun from "../layouts/LayoutBBgun.vue";
import arena1Image from "@/assets/images/gun1.png";
import { ref, onMounted } from "vue";
import {
  getDatabase,
  ref as dbRef,
  set as dbSet,
  onValue,
} from "firebase/database";
import gImage from "@/assets/images/gunCard.png";

export default {
  name: "BBgunPage",

  components: {
    LayoutBBgun,
  },

  setup() {
    const items = ref([]);
    const selectImage = ref(arena1Image);
    const grassImage = ref(gImage);

    onMounted(() => {
      const database = getDatabase();
      const stidiumRef = dbRef(database, "/bbgun");

      onValue(stidiumRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          items.value = Object.keys(data).map((key) => ({
            key,
            value: data[key],
          }));
        }
      });
    });

    //console.log(items);

    const isShow1 = ref(false);
    const editedItem = ref(null);

    // show จองสนาม

    const toggleShow1 = (value, key) => {

        isShow1.value = true;
      
      editedItem.value = {
        key,
        value: { ...items.value.find((item) => item.key === key).value },
      };
    };

    // บันทึกการจองสนาม
    const saveChanges = async () => {
      if (editedItem.value) {
        editedItem.value.value.status = 3;

        const database = getDatabase();
        const stidiumRef = dbRef(database, `/bbgun/${editedItem.value.key}`);

        await dbSet(stidiumRef, editedItem.value.value);
      }
    };

    const saveChanges2 = async () => {
      if (editedItem.value) {
        isShow1.value = false;

        editedItem.value.value.status = 2;

        const database = getDatabase();
        const stidiumRef = dbRef(database, `/bbgun/${editedItem.value.key}`);

        await dbSet(stidiumRef, editedItem.value.value);
      }
    };

    return {
      items,
      selectImage,
      isShow1,
      saveChanges,
      saveChanges2,
      editedItem,
      toggleShow1,
      grassImage,
    };
  },

  data() {
    return {
      currentDate: "",
      currentHour: 0,
      currentMinute: 0,
      currentSecond: 0,
    };
  },

  mounted() {
    this.getCurrentDateTime();
    setInterval(() => {
      this.getCurrentDateTime();
    }, 1000); // Refresh every second
  },

  methods: {
    getCurrentDateTime() {
      const now = new Date();
      // Get current date
      const dateOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      this.currentDate = now.toLocaleDateString("th-TH", dateOptions);
      const options = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      };
      // Get current time in 24-hour format
      const currentTimeString = now.toLocaleTimeString("en-US", options);
      const [currentHour, currentMinute, currentSecond] =
        currentTimeString.split(":");
      // Update data
      this.currentHour = currentHour.padStart(2, "0");
      this.currentMinute = currentMinute.padStart(2, "0");
      this.currentSecond = currentSecond.padStart(2, "0");
    },
  },
};
</script>

<style lang=""></style>
