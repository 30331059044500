<template>
  <LayoutPanel>
    <!-- <div class="mt-16 space-x-1 sm:space-x-2 flex">
      <button
        class="whitespace-nowrap bg-gray-200 rounded-lg py-1 pb-2 px-2 text-gray-700 border border-gray-300 hover:bg-gray-400 hover:text-gray-50 focus:ring-gray-700 focus:border-gray-700 focus:bg-gray-400 focus:text-white"
      >
        วันนี้
      </button>

      <input
        class="w-30 sm:w-60 bg-gray-100 rounded-lg text-sm border border-gray-300 focus:ring-gray-500 focus:border-gray-500"
        type="date"
        v-model="formattedStartDate"
      />
      <input
        class="w-30 sm:w-60 bg-gray-100 rounded-lg text-sm border border-gray-300 focus:ring-gray-500 focus:border-gray-500"
        type="date"
        v-model="formattedEndDate"
      />
      <button
        class="whitespace-nowrap bg-gray-200 rounded-lg py-1 pb-2 px-2 text-gray-700 border border-gray-300 hover:bg-gray-400 hover:text-gray-50 focus:ring-gray-700 focus:border-gray-700 focus:bg-gray-400 focus:text-white"
      >
        ลุย
      </button>
    </div> -->

    <div class="flex flex-col sm:grid sm:grid-cols-3 sm:gap-4 mt-10">
      <div class="bg-emerald-500 rounded-md text-center text-white p-5 mt-3">
        <div class="grid grid-cols-2">
          <div>
            <div class="text-white">ทั้งหมด</div>
            <div class="text-white">4</div>
          </div>
          <div class="text-4xl">
            <i class="fa-sharp fa-regular fa-globe"></i>
          </div>
        </div>
      </div>
      <div class="bg-amber-500 rounded-md text-center text-white p-5 mt-3">
        <div class="grid grid-cols-2">
          <div>
            <div class="text-white">ใช้เงินจอง</div>
            <div class="text-white">0</div>
          </div>
          <div class="text-4xl">
            <i class="fa-solid fa-money-check-dollar"></i>
          </div>
        </div>
      </div>
      <div class="bg-cyan-500 rounded-md text-center text-white p-5 mt-3">
        <div class="grid grid-cols-2">
          <div>
            <div class="text-white">ใช้แต้มจอง</div>
            <div class="text-white">4</div>
          </div>
          <div class="text-4xl">
            <i class="fa-solid fa-crown"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-2 sm:flex sm:justify-end gap-4 mt-5">
      <div class="text-center">
        <button
          id="dropdownDefaultButton"
          data-dropdown-toggle="dropdown"
          class="text-white bg-rose-500 hover:bg-rose-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
        >
          สนามฟุตบอล
          <i class="fa-sharp fa-solid fa-caret-down ml-3"></i>
        </button>
      </div>

      <div class="text-center">
        <input
          @change="changeDate()"
          type="date"
          v-model="currentDate"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
      </div>
    </div>

    <div
      id="dropdown"
      class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
    >
      <ul
        class="py-2 text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="dropdownDefaultButton"
      >
        <li>
          <a
            href="#"
            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >สนามฟุตบอล</a
          >
        </li>
        <li>
          <a
            href="#"
            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >สนามแบดมินตัน</a
          >
        </li>
        <li>
          <a
            href="#"
            class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >สนามบีบีกัน</a
          >
        </li>
      </ul>
    </div>

    <div
      class="grid grid-cols-12 bg-gradient-to-r from-zinc-950 to-zinc-500 rounded-md text-white px-2 py-1 mt-3"
    >
      <div class="text-center whitespace-nowrap text-sm sm:text-lg col-span-3">
        เบอร์ผู้ใช้
      </div>
      <div class="text-center whitespace-nowrap text-sm sm:text-lg col-span-3">
        วันที่
      </div>
      <div class="text-center whitespace-nowrap text-sm sm:text-lg col-span-2">
        เวลา
      </div>
      <div class="text-center whitespace-nowrap text-sm sm:text-lg col-span-2">
        เวลาจอง
      </div>
      <div class="text-center whitespace-nowrap text-sm sm:text-lg col-span-2">
        ชำระ
      </div>
    </div>

    <div>
      <div class="" v-for="(item, key) in dateReserve" :key="key">
        <div class="grid grid-cols-12 mt-1">
          <div
            class="text-center whitespace-nowrap text-ellipsis overflow-hidden text-sm sm:text-lg col-span-3 text-gray-500"
          >
            {{ item.username }}
          </div>
          <div
            class="text-center whitespace-nowrap text-ellipsis overflow-hidden text-sm sm:text-lg col-span-3 text-gray-500"
          >
            {{ item.date }}
          </div>
          <div
            class="text-center whitespace-nowrap text-ellipsis overflow-hidden text-sm sm:text-lg col-span-2 text-gray-500"
          >
            {{ item.time }}
          </div>
          <div
            class="text-center whitespace-nowrap text-ellipsis overflow-hidden text-sm sm:text-lg col-span-2 text-gray-500"
          >
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLc-Hm1k5Iedet9Yl'"
            >
              12:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLck45Ua6aw0-Whgd'"
            >
              13:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLe2T1ltlbJeIbBkL'"
            >
              14:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLechkLk_gHhhITjS'"
            >
              15:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLfO7S25pswVvCW_m'"
            >
              16:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLg7gNkgSzTzuuD2q'"
            >
              17:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLgtNc_TPVgs3BKRt'"
            >
              18:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLhXs10Y5bLTcjxoC'"
            >
              19:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLiT3ySB9hW5fWyY3'"
            >
              20:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLjCQPYc2y0uzriuL'"
            >
              21:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLk5IbO6y2u48C1zl'"
            >
              22:00
            </div>
            <div
              class="text-center col-span-3"
              v-if="item.ref == '-NnNLkrseCJXvZjPuHpX'"
            >
              23:00
            </div>
          </div>
          <div
            v-if="item.detail == 'ชำระเงินสำเร็จ'"
            class="bg-amber-300 border border-amber-600 text-amber-700 rounded-lg text-center whitespace-nowrap text-ellipsis overflow-hidden text-sm sm:text-lg col-span-2"
          >
            {{ item.detail }}
          </div>
          <div
            v-if="item.detail == 'ใช้แต้มสำเร็จ'"
            class="bg-cyan-300 border border-cyan-600 text-cyan-700 rounded-lg text-center whitespace-nowrap text-ellipsis overflow-hidden text-sm sm:text-lg col-span-2"
          >
            {{ item.detail }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="dateReserve == null">
      <h1 class="text-center text-gray-500">ยังไม่มีข้อมูลการจองวันนี้</h1>
    </div>

    <!-- <div class="sm:grid sm:grid-cols-2 gap-3 mt-3 sm:mt-10">
      <div class=" py-1">
        <h2 class="text-gray-500 text-center sm:text-xl">สมาชิก M Stadium</h2>

        <div
          class="grid grid-cols-4 gap-3 bg-indigo-400 rounded-lg mt-1 py-1"
        >
          <h3 class="text-center text-gray-100">เบอร์สมาชิก</h3>
          <h3 class="text-center text-gray-100">ชื่อ</h3>
          <h3 class="text-center text-gray-100">แต้มสะสม</h3>
          <h3 class="text-center text-gray-100">สถานะ</h3>
        </div>

        <div v-for="(item, key) in dataMember" :key="key">
          <div class="grid grid-cols-4 gap-3" v-if="item.status == 'member'">
            <div class="text-center text-sm text-gray-500">{{ item.username }}</div>
            <div class="text-center text-sm text-gray-500 whitespace-nowrap text-ellipsis overflow-hidden">{{ item.name }}</div>
            <div class="text-center text-sm text-gray-500">{{ item.point }}</div>
            <div class="text-center text-sm text-gray-500">{{ item.status }}</div>
          </div>
        </div>
      </div>

      <div class="text-center py-1 rounded-lg mt-3 sm:mt-0">
        <h2 class="text-gray-500 sm:text-xl">แอดมิน</h2>

        <div
          class="grid grid-cols-4 gap-3 bg-emerald-400 rounded-lg mt-1 py-1"
        >
          <h3 class="text-center text-gray-100 whitespace-nowrap">สมาชิกแอดมิน</h3>
          <h3 class="text-center text-gray-100">รหัสผ่าน</h3>
          <h3 class="text-center text-gray-100">ชื่อ</h3>
          <h3 class="text-center text-gray-100">สถานะ</h3>
        </div>

        <div v-for="(item, key) in dataMember" :key="key">
          <div class="grid grid-cols-4 gap-3" v-if="item.status == 'admin'">
            <div class="text-center text-sm text-gray-500">{{ item.username }}</div>
            <div class="text-center text-sm text-gray-500">{{ item.password }}</div>
            <div class="text-center text-sm text-gray-500">{{ item.name }}</div>
            <div class="text-center text-sm text-gray-500">{{ item.status }}</div>
          </div>
        </div>
      </div>
    </div> -->
  </LayoutPanel>
</template>

<script>
import LayoutPanel from "@/layouts/LayoutPanel.vue";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

export default {
  name: "panelPage",
  componens: {
    LayoutPanel,
  },
  components: { LayoutPanel },

  setup() {
    const router = useRouter();
    const today = ref(new Date().toISOString().split("T")[0]);
    const currentDate = ref(new Date().toISOString().split("T")[0]);

    // วันแรกของเดือน
    const startDate = ref(new Date());
    startDate.value.setDate(1);
    const formattedStartDate = startDate.value.toISOString().split("T")[0];

    //วันสุดท้ายของเดือน
    const endDate = ref(new Date(startDate.value));
    endDate.value.setMonth(startDate.value.getMonth() + 1);
    endDate.value.setDate(endDate.value.getDate() - 1);
    const formattedEndDate = endDate.value.toISOString().split("T")[0];

    // เปลี่ยนวันที่
    const changeDate = async () => {
      try {
        const response = await axios.post("https://mstadium.co/api/reserve", {
          data: currentDate.value,
        });

        dataReserve.value = response.data;
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    // ข้อมูลผู้จอง
    const dataReserve = ref("");
    const getReserve = async () => {
      try {
        const response = await axios.post("https://mstadium.co/api/reserve", {
          data: currentDate.value,
        });
        dataReserve.value = response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const dataMember = ref("");
    const getMember = async () => {
      try {
        const response = await axios.post("https://mstadium.co/api/memberApi");                                  
        dataMember.value = response.data;

        //console.log(dataMember.value);
      } catch (error) {
        console.log(error);
      }
    };

    const dateReserve = ref("");
    const getDataBetween = async () => {
      try {
        const response = await axios.get("https://mstadium.co/api/history", {
          params: {
            startDate: "2024-02-01",
            endDate: "2024-02-31",
          },
        });

        dateReserve.value = response.data;
        //console.log(dateReserve.value);
      } catch (error) {
        console.error(error);
      }
    };

    const checkLocalStorage = () => {
      const localStorageValue = localStorage.getItem("Admin");

      if (localStorageValue) {
        router.push("/panel");
      } else {
        router.push("/panelLogin");
      }
    };

    onMounted(() => {
      getReserve();
      checkLocalStorage();
      getMember();
      getDataBetween();
    });

    return {
      currentDate,
      changeDate,
      dataReserve,
      today,
      formattedStartDate,
      formattedEndDate,
      dataMember,
      dateReserve,
    };
  },
};
</script>

