<template>
  <Layout>
    <button
      class="bg-violet-500 hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 ..."
    ></button>

    <div class="p-1 rounded-lg bg-zinc-950 md:mt-10 mt-5">
      <div class="text-center text-zinc-50">
        <!-- รูปขนาดใหญ่ของสนาม -->
        <div class="bg-cover bg-center h-full relative">
          <img
            class="h-full w-full object-cover"
            :src="selectImage"
            alt="ImageStadium"
          />
        </div>
      </div>
    </div>

    <!-- รูปขนาดเล็กของสนาม -->
    <div class="text-center grid grid-cols-12 gap-2 mt-1">
      <div
        @click="selectImage = require('@/assets/images/image1.jpg')"
        class="cursor-pointer col-start-2 col-span-2 text-zinc-50 border-2 border-zinc-950"
      >
        <img :src="require('@/assets/images/image1.jpg')" alt="stadium1" />
      </div>
      <div
        @click="selectImage = require('@/assets/images/image2.jpg')"
        class="cursor-pointer col-span-2 text-zinc-50 border-2 border-zinc-950"
      >
        <img :src="require('@/assets/images/image2.jpg')" alt="stadium2" />
      </div>
      <div
        @click="selectImage = require('@/assets/images/image1.jpg')"
        class="cursor-pointer col-span-2 text-zinc-50 border-2 border-zinc-950"
      >
        <img :src="require('@/assets/images/image1.jpg')" alt="stadium3" />
      </div>
      <div
        @click="selectImage = require('@/assets/images/image2.jpg')"
        class="cursor-pointer col-span-2 text-zinc-50 border-2 border-zinc-950"
      >
        <img :src="require('@/assets/images/image2.jpg')" alt="stadium4" />
      </div>

      <div
        @click="selectImage = require('@/assets/images/image1.jpg')"
        class="cursor-pointer col-span-2 text-zinc-50 border-2 border-zinc-950"
      >
        <img :src="require('@/assets/images/image1.jpg')" alt="stadium5" />
      </div>
    </div>

    <!-- เวลา -->
    <div class="bg-zinc-950 mt-2 md:mt-5 rounded-md">
      <div class="text-center text-gray-300 p-3 md:pt-7">
        <div class="text-md md:text-3xl">{{ currentDate }}</div>
        <div class="text-gray-300 text-2xl md:text-5xl md:mt-3 md:pb-5">
          {{ currentHour }}:{{ currentMinute }}:{{ currentSecond }}
        </div>
      </div>
    </div>

    <!-- ส่วนของข้อมูลสนามที่จองแล้ว -->
    <div
      v-if="isShow1 == true && editedItem.value.status == 2"
      class="text-zinc-200"
    >
      <div class="md:grid md:grid-cols-3 mt-5 md:mt-10">
        <div
          class="col-start-2 bg-cover bg-center relative border-4 border-zinc-950 overflow-hidden rounded-md"
        >
          <img class="object-cover" :src="grassImage" alt="ImageStadium" />

          <div class="absolute inset-0 flex items-center justify-around">
            <div>
              <div class="bg-black rounded-lg px-4">
                <div class="text-zinc-200 text-xl md:text-10xl text-center">
                  ชื่อผู้จอง
                </div>
              </div>
              <div class="bg-black rounded-lg px-4 mt-1">
                <div class="text-zinc-200 text-xl md:text-10xl text-center">
                  เบอร์โทร
                </div>
              </div>
              <div class="bg-black rounded-lg px-4 mt-1">
                <div class="text-zinc-200 text-xl md:text-10xl text-center">
                  เวลา
                </div>
              </div>
              <div class="bg-black rounded-lg px-4 mt-1">
                <div class="text-zinc-200 text-xl md:text-10xl text-center">
                  สถานะ
                </div>
              </div>
            </div>
            <div>
              <div class="bg-black rounded-lg px-4 mt-1">
                <div class="text-zinc-200 text-xl md:text-10xl text-center">
                  <!-- <p class="US_NAME">MSTADIUM</p> -->
                  {{ editedItem.value.name }}
                </div>
              </div>
              <div class="bg-black rounded-lg px-4 mt-1">
                <div class="text-zinc-200 text-xl md:text-10xl text-center">
                  <!-- <p class="US_USER">MSTADIUM</p> -->
                  {{ editedItem.value.tel }}
                </div>
              </div>
              <div class="bg-black rounded-lg px-4 mt-1">
                <div class="text-zinc-200 text-xl md:text-10xl text-center">
                  {{ editedItem.value.time }}
                </div>
              </div>
              <div class="bg-black rounded-lg px-4 mt-1">
                <div class="text-zinc-200 text-xl md:text-10xl text-center">
                  จองสำเร็จ
                  <!-- {{  editedItem.value.status }} -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ส่วนของข้อมูลสนามที่ยังไม่จอง -->
    <div v-if="isShow1 == true" class="text-zinc-200">
      <div v-if="editedItem.value.status == 1 || editedItem.value.status == 3">
        <div class="md:grid md:grid-cols-3 mt-5 md:mt-10">
          <div
            class="col-start-2 bg-cover bg-center relative border-4 border-zinc-950 overflow-hidden rounded-md"
          >
            <img class="object-cover" :src="grassImage" alt="ImageStadium" />

            <div class="absolute inset-0 flex items-center justify-around">
              <div>
                <div class="bg-black rounded-lg px-4">
                  <div class="text-zinc-200 text-xl md:text-xl text-center">
                    ชื่อผู้จอง
                  </div>
                </div>

                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    เบอร์โทร
                  </div>
                </div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    เวลา
                  </div>
                </div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    สถานะ
                  </div>
                </div>
              </div>
              <div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    <!-- <p>username</p> -->
                    {{ name }}
                  </div>
                </div>

                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    <!-- <p>123456</p> -->
                    {{ username }}
                  </div>
                </div>

                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    {{ editedItem.value.time }}
                  </div>
                </div>
                <div class="bg-black rounded-lg px-4 mt-1">
                  <div class="text-zinc-200 text-xl md:text-10xl text-center">
                    ว่าง
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ขึ้น qr code -->
      <div class="sm:grid sm:grid-cols-3">
        <div v-if="editedItem.key" class="text-white text-center col-start-2">
          <div class="mt-5" v-for="(item, key) in items" :key="key">
            <div v-if="editedItem.key == item.key">
              <div
                v-if="item.value.status == 3 && editedItem.value.status == 3"
              >
                <div class="mt-4 pb-2 bg-gray-950 rounded-md">
                  <div class="grid grid-cols-2 gap-1 p-1">
                    <button
                      @click="paySelect = 1"
                      class="bg-rose-500 hover:bg-rose-700 md:py-1 rounded-md text-center"
                    >
                      QR code
                    </button>
                    <button
                      @click="paySelect = 2"
                      class="bg-indigo-500 hover:bg-indigo-700 md:py-1 rounded-md text-center"
                    >
                      ใช้แต้มสะสม
                    </button>
                  </div>
                  <div v-if="isLoading" class="loading">
                    กรุณารอ QR code ชำระเงิน...
                  </div>

                  <div v-if="imageUrl" class="image-container">
                    <div v-if="paySelect == 1">
                      <div class="text-zinc-100">QRcode ชำระเงิน</div>

                      <img
                        class="text-center h-1/2 w-1/2 md:h-40 md:w-40 md:pb-2 rounded-md"
                        :src="imageUrl"
                        alt="QRcode"
                        @load="imageLoaded"
                        @error="imageError"
                      />

                      <div class="text-zinc-100">
                        จำนวนเงิน {{ amounts }} บาท
                      </div>
                      <div class="text-zinc-100">
                        บริษัท สไตรพ์ เพย์เม้นส์ (ประเทศไทย) จำกัด
                      </div>
                    </div>
                  </div>

                  <div v-if="paySelect == 2">
                    <div class="grid grid-rows-2 mx-1 mt-2">
                      <h1 class="text-center text-gray-100">
                        แต้มของฉัน {{ userdata.point }} แต้ม
                      </h1>

                      <p class="text-center text-gray-300">
                        ใช้ 1000 เพื่อจองสนาม
                      </p>

                      <div
                        @click="payPoint(username, editedItem.key)"
                        class="bg-emerald-600 hover:bg-emerald-800 rounded-md text-center mt-2"
                      >
                        ยืนยันใช้แต้ม
                      </div>
                      <div
                        class="bg-gray-500 hover:bg-gray-700 rounded-md text-center mt-1"
                      >
                        ยกเลิก
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- {{ editedItem.key }}
            <br>
            {{ item.key }} -->

            <div
              v-if="editedItem.key == item.key"
              class="md:grid md:grid-cols-3 mt-4 pb-2"
            >
              <div class="col-start-2">
                <div class="flex justify-between gap-2">
                  <button
                    v-if="item.value.status == 1"
                    @click="
                      () => {
                        generateQRCode(username, editedItem.key, '10');
                        saveChanges();
                      }
                    "
                    class="w-full cursor-pointer bg-indigo-500 hover:bg-indigo-700 text-white px-4 py-1 rounded transition duration-300"
                  >
                    ชำระเงิน
                  </button>

                  <button
                    v-if="item.value.status == 3"
                    class="w-full cursor-pointer bg-amber-500 hover:bg-amber-700 text-white px-4 py-1 rounded transition duration-300"
                    @click="
                      () => {
                        isShow1 = false;
                        saveChanges2();
                      }
                    "
                  >
                    ยกเลิก
                  </button>

                  <button
                    v-if="item.value.status == 2"
                    class="w-full cursor-pointer bg-amber-500 hover:bg-amber-700 text-white px-4 py-1 rounded transition duration-300"
                    @click="
                      () => {
                        isShow1 = false;
                      }
                    "
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="md:grid md:grid-cols-3 mt-4 pb-2">
        <div class="col-start-2">
          <div class="flex justify-between gap-2">
            <button
              v-if="editedItem.value.status == 1"
              @click="
                () => {
                  generateQRCode(username, editedItem.key, '10');
                  saveChanges();
                }
              "
              class="w-full cursor-pointer bg-indigo-500 hover:bg-indigo-700 text-white px-4 py-1 rounded transition duration-300"
            >
              ชำระเงิน
            </button>

            <button
              v-if="
                editedItem.value.status == 3 
              "
              class="w-full cursor-pointer bg-amber-500 hover:bg-amber-700 text-white px-4 py-1 rounded transition duration-300"
              @click="
                () => {
                  isShow1 = false;
                  saveChanges2();
                }
              "
            >
              ยกเลิก
            </button>

            <button
              v-if="editedItem.value.status == 2"
              class="w-full cursor-pointer bg-amber-500 hover:bg-amber-700 text-white px-4 py-1 rounded transition duration-300"
              @click="
                () => {
                  isShow1 = false;
                }
              "
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div> -->
    </div>

    <div class="bg-black rounded-lg p-2 mt-5">
      <h1 class="text-center text-zinc-100 text-lg md:text-xl">สถานะสนาม</h1>

      <div class="grid grid-cols-4 gap-1">
        <div class="text-center text-zinc-100">
          <div class="text-center text-zinc-100">เวลา</div>
          <!-- ส่วน loop ของเวลา -->
          <div
            v-for="(item, index) in items"
            :key="index"
            class="text-zinc-100 text-sm md:text-lg"
          >
            <div class="bg-zinc-500 border-zinc-900 md:border-2">
              {{ item.value.time }}
            </div>
          </div>
        </div>

        <div class="text-center text-zinc-100">
          <div
            class="text-center text-zinc-100 whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            สนามบอล
          </div>
          <!-- ส่วน สถานะของสถานะ -->
          <div
            v-for="(item, index) in items"
            :key="index"
            class="text-zinc-100 text-sm md:text-lg"
          >
            <div v-if="item.value.status == 1">
              <div>
                <div
                  @click="toggleShow1(item.value, item.key)"
                  class="cursor-pointer bg-lime-500 border-zinc-900 md:border-2 whitespace-nowrap overflow-hidden overflow-ellipsis"
                >
                  ว่าง
                </div>
              </div>
            </div>
            <div v-if="item.value.status == 2">
              <div
                @click="toggleShow1(item.value, item.key)"
                class="cursor-pointer bg-red-600 border-zinc-900 md:border-2 whitespace-nowrap overflow-hidden overflow-ellipsis"
              >
                จองแล้ว
              </div>
            </div>
            <div v-if="item.value.status == 3">
              <div
                @click="
                  toggleShow1(item.value, item.key),
                    generateQRCode(username, item.key, '10')
                "
                class="cursor-pointer bg-amber-500 border-zinc-900 md:border-2 whitespace-nowrap overflow-hidden overflow-ellipsis"
              >
                รอชำระเงิน
              </div>
            </div>

            <div v-if="item.value.status == 5">
              <div
                class="cursor-pointer bg-gray-700 border-zinc-900 md:border-2"
              >
                ปิด
              </div>
            </div>
          </div>
        </div>
        <div class="text-center text-zinc-100">
          <div
            class="text-center text-zinc-100 whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            ชื่อ
          </div>
          <div
            v-for="(item, index) in items"
            :key="index"
            class="text-zinc-100 text-sm md:text-lg"
          >
            <div
              v-if="item.value.name == ''"
              class="bg-amber-500 border-zinc-900 md:border-2"
            >
              -
            </div>
            <div
              v-if="item.value.name != ''"
              class="bg-amber-500 border-zinc-900 md:border-2 whitespace-nowrap overflow-hidden overflow-ellipsis"
            >
              {{ item.value.name }}
            </div>
          </div>
        </div>
        <div class="text-center text-zinc-100">
          <div
            class="text-center text-zinc-100 whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            ชำระเงิน
          </div>
          <div
            v-for="(item, index) in items"
            :key="index"
            class="text-zinc-100 text-sm md:text-lg whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            <div
              v-if="item.value.status == 1"
              class="bg-blue-600 border-zinc-900 md:border-2"
            >
              -
            </div>
            <div
              v-if="item.value.status == 2"
              class="bg-blue-600 border-zinc-900 md:border-2"
            >
              จ่ายแล้ว
            </div>
            <div
              v-if="item.value.status == 3"
              class="bg-blue-600 border-zinc-900 md:border-2"
            >
              รอชำระเงิน
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
// import generatePayload from "promptpay-qr";
// import qrcode from "qrcode";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
import Layout from "@/layouts/Layout.vue";
import { ref, onMounted } from "vue";
import {
  getDatabase,
  ref as dbRef,
  set as dbSet,
  onValue,
} from "firebase/database";
import arena1Image from "@/assets/images/image1.jpg";
import gImage from "@/assets/images/footballCard.png";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "testPage",
  components: {
    Layout,
  },

  setup() {
    const items = ref([]);
    const selectImage = ref(arena1Image);
    const paySelect = ref(1);
    const grassImage = ref(gImage);

    onMounted(() => {
      const database = getDatabase();
      const stidiumRef = dbRef(database, "/stadium1");

      onValue(stidiumRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          items.value = Object.keys(data).map((key) => ({
            key,
            value: data[key],
          }));
        }
      });
    });

    const isShow1 = ref(false);
    const editedItem = ref(null);

    const toggleShow1 = (value, key) => {
      if (localStorage.getItem("username") != null) {
        isShow1.value = true;
      }

      $(".US_NAME").html(localStorage.getItem("name"));
      $(".US_USER").html(localStorage.getItem("username"));

      editedItem.value = {
        key,
        value: { ...items.value.find((item) => item.key === key).value },
      };
    };

    const saveChanges = async () => {
      if (editedItem.value) {
        editedItem.value.value.status = 3;

        const database = getDatabase();
        const stidiumRef = dbRef(database, `/stadium1/${editedItem.value.key}`);
        await dbSet(stidiumRef, editedItem.value.value);

        $.getJSON(
          "https://mstadium.co/res/action?username=" +
            localStorage.getItem("username") +
            "&name=" +
            localStorage.getItem("name") +
            "&time=" +
            editedItem.value.value.time +
            "&type=football&status=2"
        );
      }
    };

    const saveChanges2 = async () => {
      if (editedItem.value) {
        isShow1.value = false;

        editedItem.value.value.status = 1;

        const database = getDatabase();
        const stidiumRef = dbRef(database, `/stadium1/${editedItem.value.key}`);

        await dbSet(stidiumRef, editedItem.value.value);
      }
    };

    return {
      items,
      selectImage,
      grassImage,
      isShow1,
      saveChanges,
      saveChanges2,
      editedItem,
      toggleShow1,
      paySelect,
    };
  },

  data() {
    return {
      username: localStorage.getItem("username"),
      name: localStorage.getItem("name"),
      amounts: "600",

      qrCodeSvg: "",

      currentDate: "",
      currentHour: 0,
      currentMinute: 0,
      currentSecond: 0,

      currentDate2: ref(new Date().toISOString().split("T")[0]),
      currentTime: ref(
        new Date().toLocaleTimeString("en-US", { hour12: false })
      ),

      isLoading: false,
      imageUrl: "",
      userdata: "",
    };
  },

  mounted() {
    this.getCurrentDateTime();
    setInterval(() => {
      this.getCurrentDateTime();
    }, 1000);

    this.Session();
    this.userData();
  },

  methods: {
    generateQRCode(username, ref, amount) {
      this.isLoading = true; // Set loading to true while waiting for response

      console.log(amount);
      let data = {
        type: "mstadium",
        username: this.username,
        amount: this.amounts,
        ref: ref,
        detail: "ชำระเงินสำเร็จ",
        print: "json",
      };

      let config = {
        method: "post",
        url: "https://api.auto-topup.com/gateway/api",
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          this.isLoading = false; // Set loading to false when data is loaded successfully

          this.imageUrl =
            response.data.data.promptpay_display_qr_code.image_url_png;

          //console.log(this.imageUrl);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async Session() {
      var username = localStorage.getItem("username");
      if (username == "" || username == undefined) {
        $("#dropdownHoverButton").hide();
        $("#footer").show();

        return false;
      }
      await $.getJSON(
        "https://mstadium.co/res/session?username=" + username,
        function (str) {
          if (str.status == "success") {
            $("#dropdownHoverButton").show();

            $(".US_NAME").html(str.data.name);
            $(".US_USER").html(str.data.username);
            $(".US_POINT").html(str.data.point);
            $("#footer").hide();
          }
        }
      );
    },

    getCurrentDateTime() {
      const now = new Date();
      const dateOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      this.currentDate = now.toLocaleDateString("th-TH", dateOptions);
      const options = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      };
      const currentTimeString = now.toLocaleTimeString("en-US", options);
      const [currentHour, currentMinute, currentSecond] =
        currentTimeString.split(":");
      this.currentHour = currentHour.padStart(2, "0");
      this.currentMinute = currentMinute.padStart(2, "0");
      this.currentSecond = currentSecond.padStart(2, "0");

      if (
        this.currentHour === "15" &&
        this.currentMinute === "38" &&
        this.currentSecond === "40"
      ) {
        const axios = require("axios");

        let config = {
          method: "get",
          url: "https://footballstadium-1cbab-default-rtdb.asia-southeast1.firebasedatabase.app/stadium1.json",
        };
        axios
          .request(config)
          .then((response) => {
            Object.keys(response.data).forEach((key) => {
              const item = {
                key: key,
                value: { ...response.data[key] },
              };

              let data = {
                name: "",
                status: "1",
                tel: "",
              };

              let config = {
                method: "patch",
                url: `https://footballstadium-1cbab-default-rtdb.asia-southeast1.firebasedatabase.app/stadium1/${item.key}.json`,
                data: data,
              };
              axios.request(config);
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (
        this.currentHour === "10" &&
        this.currentMinute === "57" &&
        this.currentSecond === "20"
      ) {
        this.amounts = 700;
        console.log(this.amounts);
        this.generateQRCode();
      }
    },

    userData() {
      let data = {
        username: this.username,
      };

      let config = {
        method: "post",
        url: "https://mstadium.co/api/apiuser",
        data: data,
      };

      if (this.username) {
        axios
          .request(config)
          .then((response) => {
            this.userdata = response.data[0];
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    async payPoint(username, key) {
      let data = {
        name: this.name,
        tel: username,
        key: key,
        date: this.currentDate2,
        time: this.currentTime,
      };

      let config = {
        method: "put",
        url: "https://mstadium.co/api/paypoint",
        data: data,
      };

      await axios
        .request(config)
        .then((response) => {
          this.userData(response);

          if (this.userdata.point < 1000) {
            Swal.fire({
              icon: "error",
              title: "ผิดพลาด",
              text: "แต้มของคุณไม่เพียงพอต่อการจอง",
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "จองสนามสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
img {
  display: inline;
  vertical-align: middle;
}

.ellips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loading {
  /* CSS for styling the loading indicator */
}
.image-container {
  /* CSS for styling the container of the image */
}
</style>
