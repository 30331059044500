<template>
  <Layout>
    <div
      class="text-center mt-5 bg-gray-200 rounded-lg py-2 text-2xl text-gray-700"
    >
      ประวัติการจองสนาม
      <p>
        <span class="text-lg">รหัสสมาชิก</span>
        <span class="ml-3 text-rose-500">{{ username }}</span>
      </p>
    </div>

    <div
      class="rounded-lg mt-5 text-gray-600 text-lg font-bold bg-gray-50 rounded-b-lg"
    >
      <div
        class="grid grid-cols-12 gap-1 py-1 bg-gray-500 rounded-t-lg text-white"
      >
        <div class="col-span-6 text-center">วันที่</div>
        <div class="col-span-3 text-center">เวลาจอง</div>
        <div class="col-span-3 text-center">การชำระ</div>
      </div>

      <div class="" v-for="(item, key) in histories" :key="key">
        <div class="grid grid-cols-12 gap-1 text-center">
          <div class="text-el col-span-6">{{ formatThaiDate(item.date) }}</div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLc-Hm1k5Iedet9Yl'"
          >
            12:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLck45Ua6aw0-Whgd'"
          >
            13:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLe2T1ltlbJeIbBkL'"
          >
            14:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLechkLk_gHhhITjS'"
          >
            15:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLfO7S25pswVvCW_m'"
          >
            16:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLg7gNkgSzTzuuD2q'"
          >
            17:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLgtNc_TPVgs3BKRt'"
          >
            18:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLhXs10Y5bLTcjxoC'"
          >
            19:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLiT3ySB9hW5fWyY3'"
          >
            20:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLjCQPYc2y0uzriuL'"
          >
            21:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLk5IbO6y2u48C1zl'"
          >
            22:00
          </div>
          <div
            class="text-center col-span-3"
            v-if="item.ref == '-NnNLkrseCJXvZjPuHpX'"
          >
            23:00
          </div>
          <div class="text-el col-span-3">{{ item.detail }}</div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout.vue";
import axios from "axios";
export default {
  name: "HistoryPage",
  components: {
    Layout,
  },

  data() {
    return {
      username: localStorage.getItem("username"),
      histories: "",
    };
  },
  mounted() {
    this.getHistory();
  },
  methods: {
    formatThaiDate(dateString) {
      const dateOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return new Date(dateString).toLocaleDateString("th-TH", dateOptions);
    },
    getHistory() {
      let params = {
        username: this.username,
      };

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://mstadium.co/api/history",
        headers: {
          "Content-Type": "application/json",
        },
        params: params,
      };

      axios
        .request(config)
        .then((response) => {
          this.histories = response.data;
          //console.log(this.histories);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
