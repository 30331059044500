<template>
  <Layout>
    <div class="bg-white rounded-xl mt-5">
      <h1 class="text-center bg-gray-500 py-3 text-2xl rounded-t-lg text-white">
        ช่องทางติดต่อ
      </h1>
      <div class="grid grid-cols-12 gap-1 p-3">
        <!-- ติดต่อ line -->
        <div class="col-start-1">
          <i class="ml-1 fa-brands fa-line text-5xl text-green-500"></i>
        </div>
        <div class="col-start-3 col-span-3 mt-2 text-xl">ไอดีไลน์</div>
        <div class="col-start-6 col-span-6 mt-2 text-xl">@mstadium</div>

        <!-- ติดต่อ gmail -->
        <div class="col-start-1">
          <i class="fa-sharp fa-solid fa-envelope text-5xl text-cyan-600"></i>
        </div>
        <div class="col-start-3 col-span-3 mt-2 text-xl">อีเมล</div>
        <div class="col-start-6 col-span-6 mt-2 text-xl">
          mstadium@gmail.com
        </div>

        <!-- ติดต่อ เบอร์โทร -->
        <div class="col-start-1">
          <i class="fa-sharp fa-solid fa-phone text-5xl text-amber-600"></i>
        </div>
        <div class="col-start-3 col-span-3 mt-2 text-xl">เบอร์โทร</div>
        <div class="col-start-6 col-span-6 mt-2 text-xl">0123456789</div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout.vue";
export default {
  name: "ContactPAge",
  components: {
    Layout,
  },
};
</script>

<style></style>
