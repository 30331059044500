<template>
  <div>
    <chart/>
  </div>
</template>

<script>
import chart from '@/components/chart.vue';
export default {
  name : "testPage",

  components: {
    chart
  }
}
</script>

<style>

</style>