import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../pages/Home.vue";
import BadmintonPage from "@/pages/Badminton.vue";
import BbgunVue from "@/pages/Bbgun.vue";
import PanelPage from "@/pages/panel.vue";
import PanelMemberPage from "@/pages/panelMember.vue";
import PanelLoginPage from "@/pages/panelLogin.vue";
import TestPage from "@/pages/test.vue";
import ContactPage from "@/pages/Contact.vue";
import HistoryPage from "@/pages/History.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/badminton",
    name: "Badminton",
    component: BadmintonPage,
  },
  {
    path: "/bbgun",
    name: "Bbgun",
    component: BbgunVue,
  },
  {
    path: "/panel",
    name: "Panel",
    component: PanelPage,
  },
  {
    path: "/panelMember",
    name: "PanelMember",
    component: PanelMemberPage,
  },
  {
    path: "/panelLogin",
    name: "PanelLogin",
    component: PanelLoginPage,
  },
  {
    path: "/test",
    name: "TestPage",
    component: TestPage,
  },
  {
    path: "/contact",
    name: "ContactPage",
    component: ContactPage,
  },
  {
    path: "/history",
    name: "HistoryPage",
    component: HistoryPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
