<template>
  <div class="flex flex-col sm:grid sm:grid-cols-3 sm:gap-4 p-5">
    <div class="grid grid-cols-10">
      <div class="col-span-6">
        <h1 class="text-gray-500 text-xl font-extrabold">ทั้งหมด</h1>
        <h2 class="text-gray-500 text-3xl font-extrabold">75 %</h2>
      </div>
      <div class="col-span-4 text-center">
        <select
          id="countries"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <!-- <option selected>Choose a country</option> -->
          <option value="US">United States</option>
          <option value="CA">Canada</option>
          <option value="FR">France</option>
          <option value="DE">Germany</option>
        </select>
      </div>
    </div>

    <div class="text-center">2</div>
    <div class="text-center">3</div>
  </div>
</template>

<script>
export default {
  name: "chartComponent",
};
</script>

<style></style>
