<template>
  <LayoutPanel>
    <div class="relative overflow-x-auto mt-16">
      <div
        class="grid grid-cols-12 bg-gray-500 rounded-md py-1 sm:py-2 mt-2 text-white"
      >
        <div class="col-span-3 text-center text-sm sm:text-md">ID</div>
        <div class="col-span-3 text-center text-sm sm:text-md">เบอร์ผู้ใช้</div>
        <div class="col-span-2 text-center text-sm sm:text-md">ชื่อผุ้ใช้</div>
        <div class="col-span-3 text-center text-sm sm:text-md">แต้ม</div>
      </div>

      <div
        v-for="(item, key) in apiData"
        :key="key"
        class="grid grid-cols-12 mt-2"
      >
        <div class="col-span-3 text-center text-xs ellips">
          {{ item.id }}
        </div>
        <div class="col-span-3 text-center text-xs ellips">
          {{ item.username }}
        </div>
        <div class="col-span-2 text-center text-xs ellips">{{ item.name }}</div>
        <div class="col-span-3 text-center text-xs ellips">{{ item.point }}</div>
      </div>
    </div>
  </LayoutPanel>
</template>

<script>
import LayoutPanel from "@/layouts/LayoutPanel.vue";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

export default {
  name: "panelMemberPage",
  componens: {
    LayoutPanel,
  },
  components: { LayoutPanel },

  setup() {
    const router = useRouter();
    const currentDate = ref("2023-01-23");
    const apiData = ref([]);

    onMounted(async () => {
      try {
        const response = await axios.get(
          "https://api.all123th.com/inw/api/st_member?limit=20"
        );
        apiData.value = response.data;
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    });

    const checkLocalStorage = () => {
      const localStorageValue = localStorage.getItem("Admin");

      if (localStorageValue) {
        router.push("/panelMember");
      } else {
        router.push("/panelLogin");
      }
    };

    onMounted(() => {
      checkLocalStorage();
    });

    return {
      currentDate,
      apiData,
    };
  },
};
</script>

<style scoped>
.ellips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
