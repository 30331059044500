<template>
  <div class="min-h-screen bg-white bg-fixed hover:bg-fixed">
    <!-- ส่วนของ header -->
    <Header />

    <div class="pt-8 md:pt-14 min-h-screen transition-position">
      <div class="p-5 xl:max-w-7xl xl:mx-auto">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/HeaderPanel.vue";

export default {
  name: "HeaderPanel",

  components: {
    Header,
  },

  data() {
    return {
      background:
        "https://img.freepik.com/free-photo/green-fake-grass-background_53876-16313.jpg?t=st=1704364692~exp=1704365292~hmac=1dca88b81e9b0d01053776a008cd2e300f58b590f899b3599ed7782162690744",

      bg1: require("@/assets/images/arena1.png"),
    };
  },
};
</script>

<style scoped></style>
