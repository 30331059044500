<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@200&display=swap");
* {
  font-family: "Prompt", sans-serif;
  font-weight: 600;
}
</style>
