<template lang="">
  <div>
    <nav
      class="zz bg-gradient-to-r z-999 from-zinc-950 via-zinc-950 to-zinc-950 fixed w-full"
    >
      <div class="container mx-auto flex items-center justify-between">
        <a
          v-if="!mobileNavOpen"
          href="/"
          class="text-white text-xl font-bold hover:text-gray-300 transition duration-300"
        >
          <img
            class="h-28 w-28 md:h-20 md:w-20 object-contain mx-auto"
            :src="require('@/assets/images/Mstadium.png')"
            alt="arena1"
          />
        </a>

        <!-- แปลภาษา -->
        <!-- <div style="display: none">
          <GoogleTranslate />
        </div> -->

        <div class="hidden flex-grow md:flex justify-end space-x-4">
          <span class="US_NAME text-white mt-5 text-xl">M STADIUM</span>

          <div
            id="dropdownHoverButton"
            data-dropdown-toggle="dropdownHover"
            data-dropdown-trigger="hover"
            class="mt-3 block text-white bg-zinc-700 hover:bg-zinc-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
          >
            <span class="US_NAME">USERNAME</span>
            <i class="ml-3 fa-duotone fa-user"></i>
          </div>
        </div>

        <div class="md:hidden">
          <div class="flex space-x-4">
            <div class="text-center text-white mt-1">
              <p
                class="US_NAME text:md whitespace-nowrap overflow-hidden overflow-ellipsis"
              >
                M STADIUM
              </p>
            </div>

            <div>
              <button @click="toggleMobileNav" class="text-white text-2xl">
                <i class="fa-duotone fa-bars"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          v-show="mobileNavOpen"
          class="md:hidden absolute top-16 left-0 w-full bg-zinc-900"
        >
          <a
            href="/"
            class="block p-2 text-white hover:text-gray-300 transition duration-300"
          >
            <i class="ml-1 fa-solid fa-user"></i>
            <span class="ml-3 US_USER">08XXXXXXXX</span>
          </a>
          <a
            href="/"
            class="block p-2 text-white hover:text-gray-300 transition duration-300"
            ><i class="fa-duotone fa-crown"></i> คะแนน
            <span class="ml-1 US_POINT">0</span> แต้ม</a
          >
          <a
            href="/history"
            class="block p-2 text-white hover:text-gray-300 transition duration-300"
            ><i class="fa-solid fa-clock-rotate-left"></i
            ><span class="ml-2"></span>ประวัติการจองสนาม</a
          >
          <a
            href="#"
            @click="Logout()"
            class="block p-2 text-white hover:text-gray-300 transition duration-300"
            ><i class="fa-duotone fa-arrow-right-from-bracket"></i>
            ออกจากระบบ</a
          >
        </div>

        <!-- login modal -->
        <div
          id="default-modal"
          tabindex="-1"
          aria-hidden="true"
          class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div class="relative p-4 w-full max-w-2xl max-h-full">
            <!-- Modal content -->
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <h1
                class="bg-green-500 text-white text-2xl text-center pt-4 pb-4"
              >
                เข้าสู่ระบบ
              </h1>

              <div class="p-5">
                <div class="grid grid-cols-12">
                  <div class="col-span-3 text-center mt-2">เบอร์โทร</div>
                  <div class="col-span-9 text-center">
                    <input
                      class="w-full h-10"
                      type="tel"
                      maxlength="10"
                      id="username"
                      name="username"
                      placeholder="เบอร์โทรผู้ใช้"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-2 mt-5">
                  <button
                    @click="Login()"
                    data-modal-hide="default-modal"
                    type="button"
                    class="bg-emerald-600 hover:bg-emerald-800 rounded-md py-1 text-center text-white"
                  >
                    เข้าสู่ระบบ
                  </button>
                  <button
                    data-modal-hide="default-modal"
                    type="button"
                    class="bg-gray-600 hover:bg-gray-800 rounded-md py-1 text-center text-white"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- register modal -->
        <div
          id="default-modal2"
          tabindex="-1"
          aria-hidden="true"
          class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div class="relative p-4 w-full max-w-2xl max-h-full">
            <!-- Modal content -->
            <div class="relative bg-white rounded-xl shadow dark:bg-gray-700">
              <h1 class="bg-gray-700 text-white text-2xl text-center pt-4 pb-4">
                สมัครสมาชิก
              </h1>

              <div class="p-5">
                <div class="grid grid-cols-12">
                  <div class="col-span-3 text-center mt-2">เบอร์โทร</div>
                  <div class="col-span-9 text-center">
                    <input
                      class="w-full h-10"
                      type="tel"
                      maxlength="10"
                      id="username2"
                      name="username2"
                      placeholder="เบอร์โทรผู้ใช้"
                    />
                  </div>

                  <div class="col-span-3 text-center mt-2">ชื่อผู้ใช้</div>
                  <div class="col-span-9 text-center">
                    <input
                      class="w-full h-10"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="ชื่อผู้ใช้"
                    />
                  </div>
                </div>

                <div class="grid grid-cols-2 gap-2 mt-5">
                  <button
                    @click="Register()"
                    data-modal-hide="default-modal2"
                    type="button"
                    class="bg-rose-600 hover:bg-rose-800 rounded-md py-1 text-center text-white"
                  >
                    สมัครสมาชิก
                  </button>
                  <button
                    data-modal-hide="default-modal2"
                    class="bg-gray-600 hover:bg-gray-800 rounded-md py-1 text-center text-white"
                  >
                    ยกเลิก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Dropdown menu -->
        <div
          id="dropdownHover"
          class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
        >
          <ul
            class="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownHoverButton"
          >
            <li>
              <a
                href="#"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                ><i class="fa-duotone fa-user"></i>
                <span class="ml-3 US_USER">08XXXXXXXX</span>
              </a>
            </li>
            <li>
              <a
                href="#"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                ><i class="fa-sharp fa-light fa-crown"></i>
                <span class="ml-3 US_POINT">0</span> แต้ม</a
              >
            </li>
            <li>
              <a
                href="/history"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                ><i class="fa-solid fa-clock-rotate-left"></i
                ><span class="ml-2"></span>ประวัติการจองสนาม</a
              >
            </li>

            <li>
              <a
                href="#"
                @click="Logout()"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                ><i class="ml-1 fa-regular fa-arrow-right-from-bracket"></i>
                ออกจากระบบ</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <div v-if="showContent">
    <div class="cursor-pointer z-50 fixed bottom-32 right-4">
      <i
        @click="hideDiv"
        class="fa-solid fa-circle-xmark text-gray-200 text-xl"
      ></i>
    </div>
    <a
      href="/contact"
      class="z-40 fixed bottom-24 right-5 rounded-full cursor-pointer"
    >
      <img class="h-12" src="../assets/images/linelogo.png" alt="line" />
    </a>
  </div>

  <div
    id="footer"
    class="z-50 fixed bottom-0 left-0 w-full text-white p-3 bg-black"
  >
    <div class="grid grid-cols-2 gap-2">
      <div
        data-modal-target="default-modal"
        data-modal-toggle="default-modal"
        class="bg-rounded-md text-center rounded-md text-white py-2 bg-green-500 hover:bg-green-700 transition duration-300"
      >
        เข้าสู่ระบบ
      </div>
      <div
        data-modal-target="default-modal2"
        data-modal-toggle="default-modal2"
        class="bg-rounded-md text-center rounded-md text-white py-2 bg-rose-500 hover:bg-rose-800 transition duration-300"
      >
        สมัครสมาชิก
      </div>
    </div>
  </div>
</template>

<script>
//import GoogleTranslate from "../components/Translate.vue";
import axios from "axios";
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;
import Swal from "sweetalert2";

export default {
  name: "HeaderComponent",

  setup() {
    return {};
  },

  data() {
    return {
      showContent: true,
      mobileNavOpen: false,
      username: null,

      googleTranslateElementId: "google_translate_element",
    };
  },
  components: {
    // GoogleTranslate
  },

  methods: {
    hideDiv() {
      this.showContent = false;
    },
    async Login() {
      var username = $("#username").val();
      await $.getJSON(
        "https://mstadium.co/res/login?username=" + username,
        function (str) {
          if (str.status == "success") {
            localStorage.setItem("username", str.data.username);
            localStorage.setItem("name", str.data.name);
            $(".US_NAME").html(str.data.name);
            $(".US_USER").html(str.data.username);
            $(".US_POINT").html(str.data.point);
            $("#dropdownHoverButton").show();
            $("#footer").hide();
            this.username = str.data.username;

            Swal.fire({
              icon: "success",
              title: "เข้าสู่ระบบสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "ผิดพลาด",
              text: "กรุณาใส่เบอร์ผู้ใช้ให้ถูกต้อง",
            });
          }
        }
      );
    },

    async Register() {
      var username2 = $("#username2").val();
      var name = $("#name").val();

      let config = {
        method: "post",
        url: `https://mstadium.co/res/register?username=${username2}&name=${name}`,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.status == "success") {
            Swal.fire({
              icon: "success",
              title: "สมัครสมาชิกสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "ผิดพลาด",
              text: "ข้อมูลผิดพลาด",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    Logout() {
      this.username = "";
      $("#dropdownHoverButton").hide();
      $("#footer").show();
      localStorage.clear();

      window.location.reload();
    },

    async Session() {
      var username = localStorage.getItem("username");
      if (username == "" || username == undefined) {
        $("#dropdownHoverButton").hide();
        $("#footer").show();
        // dont
        return false;
      }
      await $.getJSON(
        "https://mstadium.co/res/session?username=" + username,
        function (str) {
          if (str.status == "success") {
            $("#dropdownHoverButton").show();
            localStorage.setItem("name", str.data.name);
            $(".US_NAME").html(str.data.name);
            $(".US_USER").html(str.data.username);
            $(".US_POINT").html(str.data.point);

            this.username = str.data.username;
            $("#footer").hide();
          }
        }
      );
    },
    toggleMobileNav() {
      if (localStorage.getItem("username") != null) {
        this.mobileNavOpen = !this.mobileNavOpen;
      }
    },

    translate() {
      return new Promise((resolve, reject) => {
        if (typeof window !== "undefined") {
          window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
              {
                includedLanguages: "th,zh-CN,en",
              },
              "google_translate_element"
            );
            resolve();
          };

          const script = document.createElement("script");
          script.src =
            "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";

          // Handle script load errors
          script.onerror = reject;

          document.head.appendChild(script);
        } else {
          reject(new Error("Window not available"));
        }
      });
    },

    async click() {
      var google_translate_element = document.querySelector(
        ".VIpgJd-ZVi9od-l4eHX-hSRGPd"
      );

      // var skiptranslate = document.querySelector(
      //   ".skiptranslate.goog-te-gadget"
      // );
      google_translate_element.style.display = "none";

      // console.log(skiptranslate);
    },
  },
  // mounted() {
  //   this.Session();

  // },

  async mounted() {
    await this.Session();
    // await this.translate();
    // await this.click();
  },
};
</script>

<style scoped>
.zz {
  z-index: 999;
}
</style>
