<template>
  <!-- :style="'background-image: url(' + bg + ');'" -->
  <div class="min-h-screen flex items-center justify-center bg-slate-400">
    <div
      class="bg-white p-8 rounded-lg shadow-xl w-full ml-5 mr-5 md:w-1/2 lg:w-1/3"
    >
      <h2 class="text-slate-400 text-2xl font-semibold mb-6 text-center">
        แอดมิน | M Stadium
      </h2>

      <!-- Form -->
      <form @submit.prevent="login">
        <!-- Email Input -->
        <div class="mb-4">
          <label for="usernme" class="block text-gray-600 text-sm mb-2"></label>

          <div class="flex items-center">
            <!-- <i class="fa-sharp fa-solid fa-user text-gray-500 "></i> -->
            <input
              v-model="username"
              type="text"
              id="loginId"
              name="usernme"
              placeholder="รหัสผู้ใช้"
              class="w-full p-2 border border-gray-300 rounded text-slate-400"
            />
          </div>
        </div>

        <!-- Password Input -->
        <div class="mb-6">
          <label
            for="password"
            class="block text-slate-400 text-sm mb-2"
          ></label>
          <div class="flex items-center">
            <!-- <i class="fa-sharp fa-solid fa-key text-gray-500"></i> -->
            <input
              v-model="password"
              type="password"
              id="loginPassword"
              name="password"
              placeholder="รหัสผ่าน"
              autocomplete="on"
              class="w-full p-2 border border-gray-300 rounded text-slate-400"
            />
          </div>
        </div>

        <!-- Submit Button -->
        <button
          type="submit"
          class="w-full bg-emerald-500 hover:bg-emerald-700 text-white p-2 rounded"
        >
          เข้าสู่ระบบ
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";

export default {
  name: "LoginPage",

  setup() {
    const router = useRouter();
    const bg = ref(require("@/assets/images/arena1.png"));
    const username = ref(null);
    const password = ref(null);

    const login = async () => {
      try {
        const response = await axios.get(
          `https://mstadium.co/res/loginAdmin?username=${username.value}&password=${password.value}`
        );

        if (response.data.status == "success") {
          localStorage.setItem("Admin", response.data.data.username);
          console.log("Login success:", response.data.msg);
          router.push("/panel");
          return true;
        } else {
          console.log("Login failed:", response.data.msg);
          return false;
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        return false;
      }
    };

    return {
      bg,
      username,
      password,
      login,
    };
  },
};
</script>
<style scoped>
[id="loginId"]:focus,
[id="loginPassword"]:focus {
  --tw-ring-inset: var(--tw-empty);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #0db389;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  border-color: #0db389;
}
</style>
