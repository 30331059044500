<template>
<div></div>
</template>

<script>
export default {
  name: "footerComponent",
};
</script>

<style scoped>
.zz {
  z-index: 999;
}
</style>
